import React from "react";
import Navbarfr from "../../components/Navbarfr";
import Carousel from "../../Carousel";

const Home = () => {
  return (
    <>
      <Navbarfr />
      home french
      <Carousel />
    </>
  );
};

export default Home;
