import React from "react";
import Route from "./components/Route";
import ReactDOM from "react-dom";

// ENGLISH
import Home from "./pages/en/Home";
import Bariatric from "./pages/en/Bariatric";
import Medical from "./pages/en/Medical";
import Evisa from "./pages/en/Evisa";
import About from "./pages/en/About";
import Dental from "./pages/en/Dental";
import Health from "./pages/en/Health";
import Contact from "./pages/en/Contacts";
// FRENCH
import Homefr from "./pages/fr/Home";
import Bariatricfr from "./pages/fr/Bariatric";
import Medicalfr from "./pages/fr/Medical";
import Aboutfr from "./pages/fr/About";
import Dentalfr from "./pages/fr/Dental";
import Healthfr from "./pages/fr/Health";
import Contactfr from "./pages/fr/Contacts";
import Evisafr from "./pages/fr/Evisa";

ReactDOM.render(
  <React.StrictMode>
    <>
      {/* Default / EN-US */}
      <Route path="/" component={Home} />
      <Route path="/en" component={Home} />
      <Route path="/en/home" component={Home} />
      <Route path="/en/medical" component={Medical} />
      <Route path="/en/e-visa" component={Evisa} />
      <Route path="/en/bariatric" component={Bariatric} />
      <Route path="/en/dental" component={Dental} />
      <Route path="/en/health" component={Health} />
      <Route path="/en/about" component={About} />
      <Route path="/en/contact" component={Contact} />
      {/* French */}
      <Route path="/fr" component={Homefr} />
      <Route path="/fr/home" component={Homefr} />
      <Route path="/fr/medical" component={Medicalfr} />
      <Route path="/fr/e-visa" component={Evisafr} />
      <Route path="/fr/bariatric" component={Bariatricfr} />
      <Route path="/fr/dental" component={Dentalfr} />
      <Route path="/fr/health" component={Healthfr} />
      <Route path="/fr/about" component={Aboutfr} />
      <Route path="/fr/contact" component={Contactfr} />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// export default App;
