import React from "react";
const Link = ({ to, children, nome, pass, page, sell }) => {
  const preventReload = (event) => {
    event.preventDefault();
    window.history.pushState({}, "", to);
    const navigationEvent = new PopStateEvent("navigate");
    window.dispatchEvent(navigationEvent);
  };
  if (page === "E-visa" || sell === true || pass === 3) {
    if (pass === 3) {
      return (
        <a href={to} className={""}>
          {children}
        </a>
      );
    }
    if (nome.toLowerCase() === page) {
      return (
        <a href={to} className={"nav-link active"}>
          {children}
        </a>
      );
    } else {
      return (
        <a href={to} className={"nav-link"}>
          {children}
        </a>
      );
    }
  }

  if (pass === "0") {
    if (nome === page) {
      return (
        <a href={to} className={"nav-link active"} onClick={preventReload}>
          {children}
        </a>
      );
    } else {
      return (
        <a href={to} className={"nav-link"} onClick={preventReload}>
          {children}
        </a>
      );
    }
  } else {
    return (
      <a href={to} className={nome} onClick={preventReload}>
        {children}
      </a>
    );
  }
};

export default Link;
