import React, { useRef, useEffect } from "react";
// import Order from "./Order";

export default function Paypal() {
  const paypal = useRef();
  //   let Cookies = document.cookie;
  //   Cookies.set("name", "value", {
  //     sameSite: "none",
  //     secure: true,
  //   });
  useEffect(
    () => {
      window.paypal
        .Buttons({
          createOrder: (data, actions, err) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description:
                    "Travel certificate - " +
                    document.getElementById("pais").innerHTML +
                    " passport.",
                  amount: {
                    value: document.getElementById("total").innerHTML,
                    currency_code: "USD",
                  },
                },
              ],
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
            let a = order;
            order.quantity = document.getElementById("people").value;
            order.value = document.getElementById("country").value;
            order.passport = document.getElementById("pais").innerHTML;
            document.getElementById("order").classList.add("opaco");
            document.getElementById("tblOrder").classList.remove("disable-div");
            (async () => {
              const rawResponse = await fetch(
                "https://travelhealthcertificates.com/feed/orders/",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(order),
                }
              );
              const content = await rawResponse.json();

              console.log(content);
            })();

            alert(
              "Dear " +
                a["payer"]["name"]["given_name"] +
                ", thanks for your order. Please check your e-mail " +
                a["payer"].email_address +
                " for further information. Your order ID: " +
                a.id +
                "."
            );
            document.getElementById("name").innerHTML =
              "Dear " +
              a["payer"]["name"]["surname"] +
              " " +
              a["payer"]["name"]["given_name"] +
              ", thank you for your payment.";

            document.getElementById("orderId").innerHTML = a.id;
            document.getElementById("statusOrder").innerHTML = a.status;
            document.getElementById("descriptionOrder").innerHTML =
              a.purchase_units[0].description;
            document.getElementById("documentValue").innerHTML =
              a.purchase_units[0].amount.value +
              " " +
              a.purchase_units[0].amount.currency_code;
            document.getElementById("paymentId").innerHTML =
              a.purchase_units[0].payments.captures[0].id;
            document.getElementById("createOrder").innerHTML =
              a.purchase_units[0].payments.captures[0].create_time;
            document.getElementById("completedOrder").innerHTML =
              a.purchase_units[0].payments.captures[0].update_time;
            document.getElementById("totalPaid").innerHTML =
              a.purchase_units[0].payments.captures[0].amount.value +
              " " +
              a.purchase_units[0].payments.captures[0].amount.currency_code;
            document.getElementById("emailAddress").innerHTML =
              a.payer.email_address;
            document.getElementById("country").innerHTML =
              document.getElementById("paypalId").innerHTML = a.payer.payer_id;
            document.getElementById("countryId").innerHTML =
              a.payer.address.country_code;
            // return Order(a);
            // window.location.replace("/en");
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(paypal.current);
    },
    [],
    0
  );

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
