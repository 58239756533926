import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Contacts Page</h1>;
    </div>
  );
};

export default Home;
