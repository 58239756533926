import React from "react";
import "../style/Breadcrumb.css";

import Link from "./Link";

const Breadcrumb = (props) => {
  return (
    <>
      <nav aria-label="breadcrumb" className="Breadcrumb container">
        {props.name === "Home" ? (
          <ol className="breadcrumbs top">
            <li className="breadcrumb-item active">
              <Link to="/en">Home</Link>
            </li>
          </ol>
        ) : props.name !== "E-visa" ? (
          <ol className="breadcrumbs top">
            <li className="breadcrumb-item">
              <Link to="/en">Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={props.link} title={props.title}>
                {props.name}
              </Link>
            </li>
          </ol>
        ) : (
          <ol className="breadcrumbs top">
            <li className="breadcrumb-item">
              <Link sell={true} pass={3} to="/en">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <Link sell={true} pass={3} to={props.link} title={props.title}>
                {props.name}
              </Link>
            </li>
          </ol>
        )}
      </nav>
    </>
  );
};

export default Breadcrumb;
