import React from "react";
import Navbar from "../../components/Navbar";
function Medical() {
  return (
    <>
      <Navbar link="/en/medical" title="Turkish e-visa" name="Medical" />
      <div className="container">oioioi</div>
    </>
  );
}

export default Medical;
