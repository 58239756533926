import React from "react";
import "../style/Footer.css";

import Link from "./Link";

const Footer = (props) => {
  return (
    <div className="Footer navbar">
      <div className="container">
        <footer className="row row-cols-5 py-5">
          <div className="col">
            <a
              href="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                src="/static/images/logo.svg"
                alt="Turkish E-visa"
                width="120"
                height="64"
              ></img>
            </a>
            <p className="text-muted text-center">
              Travel Health Corporation © 2022
            </p>
          </div>

          <div className="col"></div>

          <div className="col">
            <h5>Application</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  Home
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  e-Visa application
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  Medical Travel
                </a>
              </li>
            </ul>
          </div>

          <div className="col">
            <h5>Discovery</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  page={props.name}
                  to="/en/about"
                  pass="1"
                  nome="nav-link p-0 text-muted"
                >
                  About us
                </Link>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  Refund policy
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  Site Maps
                </a>
              </li>
            </ul>
          </div>

          <div className="col">
            <h5>Information</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted"></a>
              </li>
              <li className="nav-item mb-2">
                <a href="/site-maps.xml" className="nav-link p-0 text-muted">
                  e-Visa
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="#" className="nav-link p-0 text-muted">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
