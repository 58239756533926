import React from "react";
import Navbar from "../../components/Navbar";
import Carousel from "../../Carousel";
import Footer from "../../components/Footer";
import Article from "../../Article";
import Manchete from "../../Manchete";
import Breadcrumb from "../../components/Breadcrumb";

const Home = () => {
  return (
    <>
      <Navbar link="/en" sell={false} title="Turkish visa" name="Home" />
      <Carousel />
      <Breadcrumb link="/en" sell={false} title="Turkish visa" name="Home" />
      <br />
      <Manchete />
      <Article />
      <br />
      <Footer name="Home" />
    </>
  );
};

export default Home;
