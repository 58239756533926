import React from "react";
import Carousel from "../../Carousel";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

const About = () => {
  return (
    <>
      <Navbar
        link="/en/about"
        title="About Travel Health Certificates visa"
        name="About"
        sell={false}
      />
      <div className="container">
        <h1>About Page</h1>
      </div>
      <Carousel />
      <Breadcrumb link="/en/about" sell={false} title="About us" name="About" />

      <Footer name="About" />
    </>
  );
};

export default About;
