import React from "react";
import "../style/Navbar.css";

import Link from "./Link";

const Navbarfr = () => {
  return (
    <>
      <title>
        e-Visa en ligne pour la Turquie - Obtenez un visa électronique ou un
        formulaire d'entrée en Turquie
      </title>
      <nav className="Navbar navbar navbar-expand-lg navbar-dark bg-darknavbar-light">
        <div className="container">
          <img
            src="/static/images/logo.svg"
            alt="Turkish E-visa"
            width="30"
            height="24"
          />

          <Link pass="1" nome="navbar-brand" to="/fr">
            Travel Health Certificates
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link pass="0" nome="fr" to="/fr">
                  Voyage en Turquie
                </Link>
              </li>
              <li className="nav-item">
                <Link pass="0" nome="medical" to="/fr/medical">
                  Voyageurs médicaux
                </Link>
              </li>
              <li className="nav-item">
                <Link pass="0" nome="bariatric" to="/fr/bariatric">
                  Voyageurs en chirurgie bariatrique
                </Link>
              </li>
              <li className="nav-item">
                <Link pass="0" nome="dental" to="/fr/dental">
                  Voyageurs dentaires
                </Link>
              </li>
              <li className="nav-item">
                <Link pass="0" nome="health" to="/fr/health">
                  Certificats sanitaires
                </Link>
              </li>
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDarkDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Langue
                  </a>
                  <ul
                    className="dropdown-menu language"
                    aria-labelledby="navbarDarkDropdownMenuLink"
                  >
                    <li className="nav-item">
                      <Link
                        nome="dropdown-item flag-icon language-text flag-icon-us"
                        to="/en"
                      >
                        &nbsp; &nbsp; English
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        nome="dropdown-item flag-icon language-text flag-icon-fr"
                        to="/fr"
                      >
                        &nbsp; &nbsp; Français
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbarfr;
