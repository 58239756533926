import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";

const Home = () => {
  return (
    <>
      <Navbar
        link="/en/contact"
        sell={false}
        title="Turkish visa"
        name="Contact"
      />
      <Breadcrumb
        link="/en/contact"
        sell={false}
        title="Turkish visa"
        name="Contact"
      />
      <br />
      <div className="container">
        <h1>Get in touch with us:</h1>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Your name:
          </label>
          <input
            type="name"
            className="form-control"
            id="name"
            placeholder="John Doe"
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Your e-mail address:
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label">
            What can we help you?
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
            placeholder="I would like to known if it is possible to..."
          ></textarea>
        </div>
      </div>
      <Footer name="Home" />
    </>
  );
};

export default Home;
