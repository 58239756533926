import "../style/Article.css";

const ArticleList = (props) => {
  return (
    <div className="container">
      <div className="row row-cols-4">
        {/* Display the article details if article is not None */}
        {props.articles &&
          props.articles.map((article) => {
            return (
              <div key={article.id} className="col">
                <div className="card Card">
                  <img
                    src={article.image}
                    className="card-img-top zoom cardx"
                    alt={article.alt}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">{article.title}</h5>
                    <h6 className="card-subtitle text-center">
                      {article.subtitle}
                    </h6>
                    <p className="card-text text-center">
                      {article.body.substring(0, 40)}
                    </p>
                    <div className="row">
                      <div className="col text-center">
                        <a
                          href={article.link}
                          className="text-center btn btn-success zoom"
                        >
                          {article.linktext}
                        </a>
                      </div>
                    </div>
                    <p className="card-date text-center">{article.date}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ArticleList;
