import React from "react";
import Article from "../../Article";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

const Dental = () => {
  return (
    <>
      <Navbar
        link="/en/dental"
        sell={false}
        title="Turkish dental visa"
        name="Dental"
      />
      <Breadcrumb
        link="/en"
        sell={false}
        title="Turkish dental e-visa"
        name="Dental"
      />
      <br />
      <Article />
      Dental page, babe!
    </>
  );
};

export default Dental;
