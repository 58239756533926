import React from "react";
import Navbarfr from "../../components/Navbarfr";

const About = () => {
  return (
    <>
      <Navbarfr />
      <div>
        <h1>French Page</h1>;
      </div>
    </>
  );
};

export default About;
