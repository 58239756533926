import React from "react";
import Navbarfr from "../../components/Navbarfr";

const Dental = () => {
  return (
    <>
      <Navbarfr />
      Frenchhhh Dental page, babe!
    </>
  );
};

export default Dental;
