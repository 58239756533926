import Carousel from "react-bootstrap/Carousel";
import "../style/Carousel.css";

// })
//   .then((response) => response.json())
//   .then((response) => {
//     setArticles(response);
//     response = JSON.stringify(response);
//     const script = document.createElement("script");
//     script.setAttribute("type", "application/ld+json");
//     script.textContent = response;
//     script.textContent = script.textContent.replace("type", "@type");
//     document.head.appendChild(script);
//   });

const Carouselist = (props) => {
  return (
    <Carousel fade>
      {props.carousels &&
        props.carousels.map((carousel) => {
          return (
            <Carousel.Item key={carousel.id}>
              <img
                className="d-block w-100 Carousel"
                src={carousel.image}
                alt={carousel.alt}
              />
              <Carousel.Caption>
                <h3>{carousel.title}</h3>
                <h2>{carousel.subtitle}</h2>
                <p className="text-justify">{carousel.body}</p>
                <button
                  onClick={() => (window.location = carousel.link)}
                  className="btn btn-success zoom"
                >
                  Apply e-Visa
                </button>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default Carouselist;
