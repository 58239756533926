import React from "react";
import Navbarfr from "../../components/Navbarfr";

const Health = () => {
  return (
    <>
      <Navbarfr />
      Health Frenchhh
      <div className="container">Health Page</div>
    </>
  );
};

export default Health;
