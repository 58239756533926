import { useState, useEffect } from "react";
import CountryList from "./components/CountryList";

function Country() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch("https://travelhealthcertificates.com/feed/countries", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => setCountries(response))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="Country container">
      <CountryList countries={countries} />
    </div>
  );
}
export default Country;
