import React from "react";
import "../style/Navbar.css";

import Link from "./Link";

const Navbar = (props) => {
  return (
    <>
      <nav className="Navbar navbar navbar-expand-lg navbar-dark bg-darknavbar-light container-fluid">
        <div className="container">
          <img
            src="/static/images/logo.svg"
            alt="Turkish E-visa"
            width="30"
            height="24"
          />
          <Link pass="1" nome="navbar-brand" to="/en">
            Travel Health Certificates
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  pass="0"
                  page={props.name.toLowerCase()}
                  nome="home"
                  to="/en"
                  sell={props.sell}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  pass="0"
                  page={props.name.toLowerCase()}
                  nome="E-visa"
                  to="/en/e-visa"
                  sell={true}
                >
                  E-visa
                </Link>
              </li>
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDarkDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Medical Travellers
                  </a>
                  <ul
                    className="dropdown-menu language"
                    aria-labelledby="navbarDarkDropdownMenuLink"
                  >
                    <li className="nav-item">
                      <Link
                        pass="0"
                        page={props.name.toLowerCase()}
                        nome="medical"
                        to="/en/medical"
                      >
                        Medical travelers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        pass="0"
                        page={props.name.toLowerCase()}
                        nome="bariatric"
                        to="/en/bariatric"
                      >
                        Bariatric surgery travelers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        pass="0"
                        page={props.name.toLowerCase()}
                        nome="dental"
                        to="/en/dental"
                        sell={props.sell}
                      >
                        Dental travelers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        pass="0"
                        page={props.name.toLowerCase()}
                        nome="health"
                        to="/en/health"
                        sell={props.sell}
                      >
                        Health Certificates
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDarkDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Language
                  </a>
                  <ul
                    className="dropdown-menu language"
                    aria-labelledby="navbarDarkDropdownMenuLink"
                  >
                    <li className="nav-item active">
                      <Link
                        nome="dropdown-item language-text flag-icon flag-icon-us"
                        to="/en"
                      >
                        &nbsp;&nbsp;&nbsp;English
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        nome="dropdown-item language-text flag-icon flag-icon-fr"
                        to="/fr"
                      >
                        &nbsp;&nbsp;&nbsp;Français
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <li className="nav-item">
                <Link
                  pass="0"
                  page={props.name.toLowerCase()}
                  nome="about"
                  to="/en/about"
                  sell={props.sell}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  pass="0"
                  page={props.name.toLowerCase()}
                  nome="contact"
                  to="/en/contact"
                  sell={props.sell}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
