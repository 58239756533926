import { useState, useEffect } from "react";
import "./style/Manchete.css";

import MancheteList from "./components/MancheteList";

function Manchete() {
  const [manchetes, setManchetes] = useState([]);

  useEffect(() => {
    fetch("https://travelhealthcertificates.com/feed/manchetes", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => setManchetes(response))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="Manchete container">
      <MancheteList manchetes={manchetes} />
    </div>
  );
}
export default Manchete;
