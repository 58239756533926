import { useState, useEffect } from "react";
import "./style/Article.css";

import ArticleList from "./components/ArticleList";

function Article() {
  const script = document.getElementsByTagName("SCRIPT")[6]; // MAIN PAGE TAGGER
  script.setAttribute("type", "application/ld+json");
  let textContent = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://www.travelhealthcertificates.com/#organization",
        name: "Visas Turkey",
        url: "https://www.travelhealthcertificates.com/",
        sameAs: [],
        logo: {
          "@type": "ImageObject",
          "@id": "https://www.travelhealthcertificates.com/#logo",
          inLanguage: "en-US",
          url: "https://www.travelhealthcertificates.com/static/logo.png",
          contentUrl:
            "https://www.travelhealthcertificates.com/static/logo.png",
          width: 158,
          height: 28,
          caption: "Visas Turkey",
        },
        image: {
          "@id": "https://www.travelhealthcertificates.com/#logo",
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://www.travelhealthcertificates.com/#website",
        url: "https://www.travelhealthcertificates.com/",
        name: "Turkey Visa",
        description: "e Visa for Turkey",
        publisher: {
          "@id": "https://www.travelhealthcertificates.com/#organization",
        },
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate:
                "https://www.travelhealthcertificates.com/?s={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "en-US",
      },
      {
        "@type": "ImageObject",
        "@id": "https://www.travelhealthcertificates.com/#primaryimage",
        inLanguage: "en-US",
        url: "https://wallpaperaccess.com/full/3739130.jpg",
        contentUrl: "https://wallpaperaccess.com/full/3739130.jpg",
        width: 1440,
        height: 250,
        caption: "Turkey Visas online",
      },
      {
        "@type": "WebPage",
        "@id": "https://www.travelhealthcertificates.com/#webpage",
        url: "https://www.travelhealthcertificates.com/",
        name: "Turkey e-Visa Online - Obtain your Electronic Visa for Turkey",
        isPartOf: {
          "@id": "https://www.travelhealthcertificates.com/#website",
        },
        about: {
          "@id": "https://www.travelhealthcertificates.com/#organization",
        },
        primaryImageOfPage: {
          "@id": "https://www.travelhealthcertificates.com/#primaryimage",
        },
        datePublished: "2020-12-12T12:43:30+00:00",
        dateModified: "2022-06-22T08:59:48+00:00",
        description:
          "The Turkish e-Visa is a travel authorization that grants its holder authorization to travel to Türkiye. Learn how to obtain your e-Visa for Türkiye here.",
        breadcrumb: {
          "@id": "https://www.travelhealthcertificates.com/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://www.travelhealthcertificates.com/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.travelhealthcertificates.com/#breadcrumb",
        itemListElement: [
          { "@type": "ListItem", position: 1, name: "Turkey e-Visa" },
        ],
      },
    ],
  };
  script.textContent = JSON.stringify(textContent);
  document.head.appendChild(script);

  const [articles, setArticle] = useState([]);

  useEffect(() => {
    fetch("https://travelhealthcertificates.com/feed/articles", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => setArticle(response))
      .catch((error) => console.log(error));
  }, []);

  return <ArticleList articles={articles} />;
}

export default Article;
