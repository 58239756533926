import React from "react";
import Navbarfr from "../../components/Navbarfr";

import Manchete from "../../Manchete";
import Article from "../../Article";
const Medical = () => {
  return (
    <>
      <Navbarfr />
      <Manchete />
      <Article />
      <div className="container">Medical French page, babe!</div>
    </>
  );
};

export default Medical;
