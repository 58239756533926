import React from "react";
import Navbarfr from "../../components/Navbarfr";

const Home = () => {
  return (
    <>
      <Navbarfr />
      French Bariatric
    </>
  );
};

export default Home;
