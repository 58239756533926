import React from "react";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import Article from "../../Article";
import Carousel from "../../Carousel";

const Home = () => {
  return (
    <>
      <Navbar
        link="/en/bariatric"
        title="Turkish bariatric visa"
        name="Bariatric"
        sell={false}
      />
      <Carousel />
      <Breadcrumb
        link="/en/bariatric"
        sell={false}
        title="Turkish visa for bariatric cirurgy"
        name="Bariatric"
      />

      <br />
      <Article />
    </>
  );
};

export default Home;
