const MancheteList = (props) => {
  return (
    <div>
      {/* Display the article details if article is not None */}
      {props.manchetes &&
        props.manchetes.map((manchete) => {
          return (
            <div key={manchete.id} className="container">
              <h1 id={manchete.id} className="text-left">
                {manchete.title}
              </h1>
              <hr />
              <h2>{manchete.subtitle}</h2>
              <p className="text-justify">{manchete.body}</p>
              <p>
                Check out more@this link: &nbsp;
                <a href={manchete.link}>{manchete.linktext}</a>
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default MancheteList;
