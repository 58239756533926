import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import "../../style/Paypal.css";
import "../../style/Evisa.css";
import Paypal from "../../components/Paypal";
import Country from "../../Country";

function Evisa() {
  return (
    <>
      <Navbar link="/en/e-visa" title="Turkish e-visa" name="E-visa" />
      <Breadcrumb
        link="/en/e-visa"
        sell={true}
        title="Turkish e-visa"
        name="E-visa"
      />

      <div id="order" className="container">
        <div className="Paypal">
          <h1>Here you can apply for the e-visa</h1>
          <img
            className="card-img-top"
            src="https://s3.ivisa.com/website-assets/blog/image-one-tur.png"
            alt="Visa Certified"
          ></img>
          <div className="card-body">
            <h5 className="card-title text-center">
              <Country />
            </h5>
            <p id="text-change" href="#apply" className="card-text">
              Travellers can buy their certificate through this website without
              being molested by people who does not care about their costumers.
            </p>
            );
          </div>
        </div>

        <div>
          {/* {checkout ? ( */}

          <Paypal />
          {/* ) : (
            <button
              onClick={() => {
                setCheckout(true);
              }}
            >
              Checkout
            </button>
          )} */}
        </div>
      </div>
      <p id="text-change2" href="#apply" className="container small">
        Total value for 1 person from Spain is: 100 USD.
      </p>
      <div id="tblOrder" className="disable-div container">
        <table className="tblOrder">
          <thead>
            <tr>
              <th id="name" colSpan="3" scope="col"></th>
            </tr>
            <tr>
              <th colSpan="3" scope="col">
                Below you can find more information about your order:
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Order ID:</td>
              <td id="orderId"></td>
            </tr>
            <tr>
              <td>Status:</td>
              <td id="statusOrder"></td>
            </tr>
            <tr>
              <td>Description:</td>
              <td id="descriptionOrder"></td>
            </tr>
            <tr>
              <td>Document value:</td>
              <td id="documentValue"></td>
            </tr>
            <tr>
              <td>Total paid:</td>
              <td id="totalPaid"></td>
            </tr>
            <tr>
              <td>E-mail:</td>
              <td id="emailAddress"></td>
            </tr>

            <tr>
              <td>Payment ID:</td>
              <td id="paymentId"></td>
            </tr>
            <tr>
              <td>Order created at:</td>
              <td id="createOrder"></td>
            </tr>
            <tr>
              <td>Order completed:</td>
              <td id="completedOrder"></td>
            </tr>
            <tr>
              <td>Your Paypal ID:</td>
              <td id="paypalId"></td>
            </tr>
            <tr>
              <td>Country of buy:</td>
              <td id="countryId"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer name="Evisa" />
    </>
  );
}

export default Evisa;
