import "../style/Country.css";

const CountryList = (props) => {
  function update() {
    try {
      document.getElementById("pais").innerHTML =
        document.getElementById("country")[
          document.getElementById("country").selectedIndex
        ].innerHTML;
      document.getElementById("total").innerHTML =
        document.getElementById("country").value *
        document.getElementById("people").value;
      document.getElementById("traveller").innerHTML =
        document.getElementById("people").value > 1
          ? (document.getElementById("traveller").innerHTML = "Travellers")
          : (document.getElementById("traveller").innerHTML = "Traveller");
      document.getElementById("text-change").innerHTML =
        document.getElementById("traveller").innerHTML +
        " from " +
        document.getElementById("pais").innerHTML +
        " can buy their certificate through this website without being molested by people who does not care about their costumers.";

      document.getElementById("text-change2").innerHTML =
        document.getElementById("people").value === "1"
          ? "Total value for " +
            document.getElementById("people").value +
            " person from " +
            document.getElementById("pais").innerHTML +
            " is: " +
            document.getElementById("total").innerHTML +
            " USD."
          : "Total value for " +
            document.getElementById("people").value +
            " people from " +
            document.getElementById("pais").innerHTML +
            " is: " +
            document.getElementById("total").innerHTML +
            " USD.";
      // document.getElementById("total").classList.remove("invisible");
    } catch {}
  }
  return (
    <div className="Country">
      Turkish certificate - select your passport:
      <select id="country" onChange={() => update()}>
        {/* Display the article details if article is not None */}
        {props.countries &&
          props.countries.map((country) => {
            return (
              <option
                name={country.name}
                key={country.id}
                value={country.price}
              >
                {country.name}
              </option>
            );
          })}
      </select>
      <br />
      <label className="visible" id="traveller">
        How many travellers?
      </label>
      <select id="people" onChange={(val) => update()}>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </select>{" "}
      <label className="invisible" id="total">
        100
      </label>
      <label className="invisible" id="pais">
        Spain
      </label>
    </div>
  );
};

export default CountryList;
