import React from "react";
import Navbar from "../../components/Navbar";

const Health = () => {
  return (
    <>
      <Navbar
        link="/en/health"
        sell={false}
        title="Turkish and health visa"
        name="Health"
      />
      <div className="container">Salve viado</div>
    </>
  );
};

export default Health;
